import { useContext, useRef } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { ANALYTICS } from '../store/Constants'

function useAnalytics(screen) {
  const { publishCdmEvents } = useContext(ConfigContext)
  const done = useRef(false)

  const { activity, screenPath, screenName } = screen

  const _buildEvent = (action, { name, detail, mode, actionAuxParams }) => {
    const eventDetail = {
      action,
      activity,
      screenPath,
      screenName,
      ...(mode ? { screenMode: mode } : {}),
      ...(name ? { controlName: name } : {}),
      ...(detail ? { controlDetail: detail } : {}),
      ...(actionAuxParams ? { actionAuxParams: actionAuxParams } : {}),
      version: ANALYTICS.SIMPLE_UI_VERSION
    }

    return [
      {
        dateTime: new Date().toISOString(),
        eventDetailType: ANALYTICS.SIMPLE_UI_EVENT_DETAIL_TYPE,
        eventCategory: ANALYTICS.SIMPLE_UI_EVENT_CATEGORY,
        version: ANALYTICS.ENVELOPE_VERSION,
        eventDetail
      }
    ]
  }

  const fireScreenDisplayed = (props = {}) => {
    if (done.current && !props.force) return
    done.current = true
    publishCdmEvents(_buildEvent('ScreenDisplayed', props))
  }

  const fireButtonClick = (name, props = {}) => {
    publishCdmEvents(_buildEvent('ControlButtonClicked', { name, ...props }))
  }

  const fireHyperLinkClick = (name, props = {}) => {
    publishCdmEvents(_buildEvent('ControlHyperLinkClicked', { name, ...props }))
  }

  return {
    fireScreenDisplayed,
    fireButtonClick,
    fireHyperLinkClick
  }
}

export default useAnalytics
